import React, {  } from 'react'
import './App.css';
import {
  Route,
  Switch,
  BrowserRouter,
  Redirect
} from 'react-router-dom'
import Footer from './components/Footer';
import Dexie from 'dexie';
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import { ThemeProvider } from '@fluentui/react'

import { themeBMS } from './themes'
// Pages
const HomeContainer = React.lazy(() => import('./components/HomeContainer'));
const ParametrosContainer = React.lazy(() => import('./components/ParametrosContainer'));



function App() {
  return (
    <>
      <div class="ms-Grid" dir="ltr" style={{paddingLeft: 0, paddingRight: 0}}>
        <BrowserRouter>
          <React.Suspense fallback={'Cargando...'}>
            <Switch>
              <Route exact path="/" name="Home" render={props => <HomeContainer {...props} />} />
              <Route exact path="/autologin" name="Home" render={props => <Redirect to="/" />} />
              <Route exact path="/smd" name="Calculadora Síndromes mielodisplásicos" render={props => <ParametrosContainer {...props} calculadora={'SMD'} />} />
              <Route path={`/smd/parametros`} name="Calculadora Síndromes mielodisplásicos" render={props => <ParametrosContainer {...props} calculadora={'SMD'} />} />
              <Route exact path="/thalasemia" name="Calculadora Thalasemia" render={props => <ParametrosContainer {...props} calculadora={'Thalasemia'} />} />
              <Route path={`/thalasemia/parametros`} name="Calculadora Thalasemia" render={props => <ParametrosContainer {...props} calculadora={'Thalasemia'} />} />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </div>
      <Footer/>
    </>
    
  );
}

export default App;