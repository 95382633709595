export const themeBMS = {
    palette: {
        themePrimary: '#5f259f',
        themeLighterAlt: '#fcf5fc',
        themeLighter: '#f5d7f4',
        themeLight: '#ecb5eb',
        themeTertiary: '#d975d7',
        themeSecondary: '#c740c5',
        themeDarkAlt: '#5f259f',
        themeDark: '#91218f',
        themeDarker: '#6b196a',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#cfd5dd',
        neutralSecondary: '#a4adba',
        neutralPrimaryAlt: '#7f8b9a',
        neutralPrimary: '#7A99AC',
        neutralDark: '#555f6b',
        black: '#3f464f',
        white: '#ffffff',
    }
};

export const themeThalasemia = {
    palette: {
        themePrimary: '#E10098',
        themeLighterAlt: '#fcf5fc',
        themeLighter: '#f5d7f4',
        themeLight: '#edc2e6',
        themeTertiary: '#7a97ab',
        themeSecondary: '#86205e',
        themeDarkAlt: '#E10098',
        themeDark: '#91218f',
        themeDarker: '#6b196a',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#cfd5dd',
        neutralSecondary: '#a4adba',
        neutralPrimaryAlt: '#7f8b9a',
        neutralPrimary: '#7A99AC',
        neutralDark: '#555f6b',
        black: '#3f464f',
        white: '#ffffff',
    }
};

export const themeSMD = {
    palette: {
        themePrimary: '#5F259F',
        themeLighterAlt: '#fcf5fc',
        themeLighter: '#f5d7f4',
        themeLight: '#d3cae8',
        themeTertiary: '#c4d600',
        themeSecondary: '#e2168f',
        themeDarkAlt: '#5F259F',
        themeDark: '#91218f',
        themeDarker: '#6b196a',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#cfd5dd',
        neutralSecondary: '#a4adba',
        neutralPrimaryAlt: '#7f8b9a',
        neutralPrimary: '#7A99AC',
        neutralDark: '#555f6b',
        black: '#3f464f',
        white: '#ffffff',
    }
};